<template>
    <v-container
        fuid
    >
        <v-row
            justify="center"
        >
            <v-col
                xs="1"
                sm="2"
                md="2"
                lg="1"
                xl="1"
                class="pa-0 pa-0 ma-0"
            >
                <div
                    :class="$vuetify.breakpoint.width > 426 ? undefined : $vuetify.breakpoint.width < 321 ? 'px-custom-320' : 'px-custom-425'"   
                >
                    <v-img
                        contain
                        src="okok.gif"
                    />
                </div>
            </v-col>
        </v-row>
        <v-row
            justify="center"
        >
            <v-col
                sm="8"
                md="5"   
                class="pa-0 ma-0"             
            >
                <p>
                    <b>{{ $t('exemption-success-thanks') }}</b>
                </p>
            </v-col>
        </v-row>
        <v-row
            justify="center"
        >
            <v-col
                sm="8"
                md="5"    
                class="pa-0 ma-0 px-5"
            >
                <p class="text-wrap">
                    <b>{{ $t('exemption-success-confirmation') }}</b>
                </p>
            </v-col>
        </v-row>
        <v-row
            justify="center"
        >
            <v-col
                sm="8"
                md="5"  
                class="pa-0 ma-0"
            >
                <v-btn
                    class="mt-5 btnWithRoundedCorner btnHeight42px"
                    elevation="1"
                    tile      
                    @click="() => this.$router.push({name: 'Index'})"
                >
                    {{ $t('back-to-homepage') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name:'Success' 
}
</script>

<style scoped>
.container{
    margin-top:60px;
}
.px-custom-320{
    padding-left: 100px !important;
    padding-right: 100px !important;
}
.px-custom-425{
    padding-left: 140px !important;
    padding-right: 140px !important;
}
</style>